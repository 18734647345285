import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, compact, flatten } from 'lodash';
import { Row, Col } from 'reactstrap';

// Models
import { InventoryEntities } from 'client/data/models/inventory';
import { VisitorEntities } from 'client/data/models/visitor';

// Utils
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { getPlural } from 'client/utils/plural';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { getUsurpImages } from 'site-modules/shared/utils/inventory/srp-images';
import { getPhotoProvider } from 'site-modules/shared/utils/inventory-image-utils';
import {
  getVdpLink,
  getDeliveryText,
  getPaymentDetails,
  getDisplayBelowMarketCopy,
  getVehicleName,
  transformDerivedFeatures,
} from 'site-modules/shared/utils/inventory/usurp-inventory-card';
import {
  isInstockOnline,
  isFiveStarDealer as getIsFiveStarDealer,
  isPartnerDealer,
  isCarMax,
  isAMSI,
  isDriveway,
  isDeliveryEligible,
} from 'site-modules/shared/utils/dealer-details-utils';
import { isUsedDealCheckAvailable } from 'site-modules/shared/utils/car-buying/is-used-deal-check-available';
import { toShortFormattedAddress } from 'site-modules/shared/utils/address-format';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { getEngineAttr } from 'client/site-modules/shared/components/inventory/utils/get-engine-attr';
import { getEngineAttrIcon } from 'client/site-modules/shared/components/inventory/utils/get-engine-attr-icon';
import { isRecurrentAvailable } from 'client/site-modules/shared/utils/inventory/is-recurrent-available';
import { isDealerPriceAvailable } from 'site-modules/shared/utils/inventory-utils/is-dealer-price-available';
import {
  getIconClasses,
  getTitleDisplayDescription,
  getOwnerDisplayDescription,
  getUsageTypeDisplayDescription,
} from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-helper';

// Constants
import { LEASE_PAYMENT, LOAN_PAYMENT } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { CARMAX } from 'site-modules/shared/constants/inventory/buy-online-dealers';
import { DEFAULT_PRICE } from 'site-modules/shared/components/deal/deal-constants';

// Components
import { ColorSwatch } from 'site-modules/shared/components/color-swatch/color-swatch';
import { ContactDealerSrp } from 'site-modules/shared/components/inventory/contact-dealer/contact-dealer-srp';
import { PricingDetails } from 'site-modules/shared/components/usurp-inventory-card/pricing-details';
import { UsurpInventoryCardPhoto } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-photo';
import { RecurrentInsights } from 'client/site-modules/shared/components/inventory/recurrent-insights/recurrent-insights';
import { TopFeatureBubbles } from 'site-modules/shared/components/inventory/top-feature-bubbles/top-feature-bubbles';
import { UsurpInventoryCardInfo } from 'site-modules/shared/components/inventory/usurp-inventory-card-info/usurp-inventory-card-info';
import { VehicleNameDisplay } from 'site-modules/shared/components/inventory/vehicle-name-display/vehicle-name-display';
import { UsurpCardViewMore } from 'site-modules/shared/components/inventory/usurp-card-view-more/usurp-card-view-more';
import { UsurpCardKeyPoint } from 'site-modules/shared/components/inventory/usurp-card-key-point/usurp-card-key-point';
import { RatingStars } from 'site-modules/shared/components/rating-stars/rating-stars';
import { ClickableCard } from 'site-modules/shared/components/inventory/clickable-card/clickable-card';
import { ClickableCardLink } from 'site-modules/shared/components/inventory/clickable-card/clickable-card-link/clickable-card-link';
import { UsurpInventoryCardVdpLink } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-vdp-link';

import './usurp-inventory-card.scss';

// body types were taken from https://qa-21-www.edmunds.com/api/inventory/v5/aggregate?by=vehicleInfo.styleInfo.bodyType&top=20
const BODY_TYPES_SRP_EXPANDED = [
  'SUV',
  'Sedan',
  'Pickup',
  'Hatchback',
  'Coupe',
  'Minivan',
  'Convertible',
  'Station Wagon',
  'Full Size Van',
];
const ASSIGNED_DEALER_NAMES = ['Carvana', 'Shift', 'EchoPark Automotive', 'Enterprise Car Sales'];
const IN_STOCK_ONLINE = 'In-stock online';
const DEFAULT_COLOR = 'Unknown';

export function KeyPointSimilarCard({ icon, iconTitle, content, classes }) {
  return (
    <Fragment>
      <span title={iconTitle} className={classnames(icon, classes, 'key-point-icon d-inline-block size-12 mr-0_5')} />
      {content}
    </Fragment>
  );
}

KeyPointSimilarCard.propTypes = {
  icon: PropTypes.string.isRequired,
  iconTitle: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  classes: PropTypes.string.isRequired,
};

export function ColorInfo({ color, colorName, label, className, isSrpExpanded, style, colorSide }) {
  return (
    <div className={classnames('size-14', className)} style={style}>
      <ColorSwatch
        color={color}
        displayColorName={false}
        title={`${colorSide} color: ${colorName}`}
        classNames={classnames('rounded', { 'align-text-top': !isSrpExpanded })}
        style={{
          width: '0.75rem',
          height: '0.75rem',
          borderWidth: '1px',
          display: 'inline-block',
          marginRight: '0.25rem',
        }}
      />
      {isSrpExpanded ? <span style={{ verticalAlign: 'text-bottom' }}>{label}</span> : label}
    </div>
  );
}

ColorInfo.propTypes = {
  color: PropTypes.shape({}),
  colorName: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  isSrpExpanded: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  colorSide: PropTypes.string,
};

ColorInfo.defaultProps = {
  color: null,
  colorName: null,
  className: '',
  isSrpExpanded: false,
  style: {},
  colorSide: '',
};

export class UsurpInventoryCard extends PureComponent {
  static propTypes = {
    vehicle: InventoryEntities.InventoryVin.isRequired,
    visitorLocation: VisitorEntities.Location,
    radius: PropTypes.number.isRequired,
    className: PropTypes.string,
    paymentType: PropTypes.string,
    selectedPaymentType: PropTypes.string,
    isNational: PropTypes.bool,
    isMobile: PropTypes.bool,
    disableInventoryMLPhotoSort: PropTypes.bool,
    clientSideRenderImage: PropTypes.bool,
    lazyLoadImage: PropTypes.bool,
    importance: PropTypes.string,
    isSimilarCards: PropTypes.bool,
    isLlmCard: PropTypes.bool,
    grayIcons: PropTypes.bool,
    isEnhancedName: PropTypes.bool,
    showNationalDelivery: PropTypes.bool,
    isVdpPartnerListing: PropTypes.bool,
    isViewed: PropTypes.bool,
    isDealerSrp: PropTypes.bool,
    isExpandedRadius: PropTypes.bool,
    showCtaButton: PropTypes.bool,
    showInventoryHeart: PropTypes.bool,
    showInventoryBadge: PropTypes.bool,
    tabIndex: PropTypes.number,
    isSrp: PropTypes.bool,
    searchId: PropTypes.string,
    isShop2901Enabled: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    visitorLocation: {},
    paymentType: '',
    selectedPaymentType: '',
    isNational: false,
    isMobile: false,
    disableInventoryMLPhotoSort: false,
    clientSideRenderImage: false,
    lazyLoadImage: false,
    importance: null,
    isSimilarCards: false,
    isLlmCard: false,
    grayIcons: false,
    isEnhancedName: false,
    showNationalDelivery: false,
    isVdpPartnerListing: false,
    isViewed: false,
    isDealerSrp: false,
    isExpandedRadius: false,
    showCtaButton: true,
    showInventoryHeart: true,
    showInventoryBadge: true,
    tabIndex: 0,
    isSrp: false,
    searchId: undefined,
    isShop2901Enabled: false,
  };

  getCtaElement = (ariaLabel, photoUrls) => {
    const {
      vehicle,
      radius,
      isNational,
      isMobile,
      selectedPaymentType,
      isSimilarCards,
      isVdpPartnerListing,
      isDealerSrp,
      showCtaButton,
      tabIndex,
      isSrp,
      visitorLocation,
    } = this.props;
    const { type, dealerInfo } = vehicle;

    const isNewType = isNew(type);
    const isPartner = isPartnerDealer(vehicle);
    const isLeaseCard = selectedPaymentType === LEASE_PAYMENT;
    const parentDealershipName = get(vehicle, DATA_PATH.PARENT_DEALERSHIP_NAME, '');
    const isCarMaxDealer = isCarMax(parentDealershipName);
    const isAMSIDealer = isAMSI(parentDealershipName);
    const isDrivewayDealer = isDriveway(parentDealershipName);
    const isTCPADisclaimerEnabled = isCarMaxDealer || isAMSIDealer;
    const tcpaParentDealershipName = isTCPADisclaimerEnabled ? parentDealershipName : null;
    const distance = Math.round(get(dealerInfo, 'distance', 0));
    const zipCode = get(visitorLocation, 'zipCode');

    const isInStockOnline =
      isNewType &&
      isDrivewayDealer &&
      isInstockOnline({ distance, radius, isDeliveryAvailable: isDeliveryEligible(vehicle, zipCode) });

    if (isVdpPartnerListing) {
      return null;
    }

    if (isNewType) {
      return (
        <ContactDealerSrp
          vinData={vehicle}
          tabIndex={tabIndex}
          rootClassName={classnames('no-gutters margin-top-auto pt-1', {
            'd-block': showCtaButton,
            'd-none': !showCtaButton,
          })}
          radius={radius}
          isNational={isNational}
          isMobile={isMobile}
          showLeasePayments={isLeaseCard}
          buttonColor={classnames('srp-cta text-capitalize size-16 font-weight-medium', {
            'px-1': !isSimilarCards,
            'px-0_75': isSimilarCards,
          })}
          isSimilarCards={isSimilarCards}
          ariaLabel={ariaLabel}
          isDealerSrp={isDealerSrp}
          showInTransit={isSrp}
          photoUrl={photoUrls[0]}
          isInStockOnline={isInStockOnline}
        />
      );
    }

    if (isUsedDealCheckAvailable(vehicle) || isPartner) {
      return (
        <ContactDealerSrp
          vinData={vehicle}
          rootClassName={classnames('no-gutters margin-top-auto pt-1', {
            'd-block': showCtaButton,
            'd-none': !showCtaButton,
          })}
          tabIndex={tabIndex}
          radius={radius}
          isNational={isNational}
          isMobile={isMobile}
          showLeasePayments={isLeaseCard}
          tcpaParentDealershipName={tcpaParentDealershipName}
          buttonColor="srp-cta text-capitalize font-weight-medium size-16 px-1"
          isSimilarCards={isSimilarCards}
          ariaLabel={ariaLabel}
          isDealerSrp={isDealerSrp}
          photoUrl={photoUrls[0]}
        />
      );
    }

    return null;
  };

  getVehicleNameAriaLabel() {
    const {
      isSimilarCards,
      vehicle,
      isEnhancedName,
      vehicle: { prices },
    } = this.props;

    const vehicleName = flatten([getVehicleName(vehicle, isEnhancedName, !isSimilarCards)]).join(' ');

    const vehiclePrice =
      isDealerPriceAvailable(vehicle) && !this.isLeaseCardWithLeasePayment() && !this.isLoanCardWithLoanPayment()
        ? get(vehicle, DATA_PATH.DEALER_PRICE)
        : get(prices, 'displayPrice', 0);
    return `${vehiclePrice ? formatPriceString(vehiclePrice) : DEFAULT_PRICE} ${vehicleName}`;
  }

  isLeaseCardWithLeasePayment() {
    const {
      selectedPaymentType,
      vehicle: { prices },
    } = this.props;

    const isLeaseCard = selectedPaymentType === LEASE_PAYMENT;
    const leasePayment = get(prices, 'estimateLeasePromise.monthlyPayment', 0);
    return Boolean(isLeaseCard && leasePayment);
  }

  isLoanCardWithLoanPayment() {
    const {
      selectedPaymentType,
      vehicle: { prices },
    } = this.props;

    const isLoanCard = selectedPaymentType === LOAN_PAYMENT;
    const loanPayment = get(prices, 'loan.payment', 0);
    return Boolean(isLoanCard && loanPayment);
  }

  render() {
    const {
      className,
      vehicle,
      visitorLocation,
      radius,
      paymentType,
      isNational,
      isMobile,
      selectedPaymentType,
      disableInventoryMLPhotoSort,
      clientSideRenderImage,
      lazyLoadImage,
      importance,
      isSimilarCards,
      isLlmCard,
      grayIcons,
      isEnhancedName,
      showNationalDelivery,
      isVdpPartnerListing,
      isViewed,
      isExpandedRadius,
      showInventoryHeart,
      showInventoryBadge,
      tabIndex,
      isSrp,
      searchId,
      isShop2901Enabled,
    } = this.props;
    const { type, vehicleInfo, historyInfo, dealerInfo, prices, vin } = vehicle;
    const mileage = get(vehicleInfo, 'mileage', 0);
    const distance = Math.round(get(dealerInfo, 'distance', 0));
    const savings = get(vehicle, 'computedDisplayInfo.priceValidation.priceDiscount', 0);
    const exteriorColor = get(vehicleInfo, 'vehicleColors.exterior');
    const exteriorColorName = get(exteriorColor, 'name', DEFAULT_COLOR);
    const interiorColor = get(vehicleInfo, 'vehicleColors.interior');
    const interiorColorName = get(interiorColor, 'name', DEFAULT_COLOR);
    const isNewType = isNew(type);
    const isUsedType = isUsed(type);

    const dealerAddress = get(dealerInfo, 'address');
    const dealerStateCode = get(dealerAddress, 'stateCode');
    const accidentText = get(historyInfo, 'accidentText');
    const cleanTitle = get(historyInfo, 'cleanTitle');
    const { photoUrls, hasPhotos, photoType } = getUsurpImages(vehicle, disableInventoryMLPhotoSort);

    const vehicleName = getVehicleName(vehicle, isEnhancedName);
    const parentDealershipName = get(vehicle, DATA_PATH.PARENT_DEALERSHIP_NAME, '');
    const isDrivewayDealer = isDriveway(parentDealershipName);
    const isCarmaxDealer = isCarMax(parentDealershipName);
    const isPartner = isPartnerDealer(vehicle);
    const zipCode = visitorLocation?.zipCode;
    const visitorStateCode = visitorLocation?.stateCode;
    const deliveryText = getDeliveryText(
      isSrp ? { vehicle, isNational, isCarmaxDealer, radius, visitorStateCode } : { vehicle, isNational }
    );
    const isFiveStarDealer = getIsFiveStarDealer(vehicle);
    const isDeliveryAvailable = isDeliveryEligible(vehicle, zipCode);
    let dealerLocationLabel;
    let isBoldDealerLocationLabel = false;
    const isInStockOnline =
      (!isNewType || isDrivewayDealer) && isInstockOnline({ distance, radius, isDeliveryAvailable });
    if (showNationalDelivery) {
      dealerLocationLabel = isDeliveryAvailable ? IN_STOCK_ONLINE : toShortFormattedAddress(dealerAddress);
    } else {
      dealerLocationLabel = isInStockOnline
        ? IN_STOCK_ONLINE
        : `${numberWithCommas(distance)} ${isSimilarCards ? getPlural('mile', distance) : 'mi'} away`;
      isBoldDealerLocationLabel = isExpandedRadius && !isDeliveryAvailable && distance >= 100 && !isInStockOnline;
    }
    const isGrayIcons = grayIcons || isSimilarCards;
    const cgfFeatures = get(vehicleInfo, 'partsInfo.cgf', []);
    const topFeatures = cgfFeatures && cgfFeatures.length ? cgfFeatures : transformDerivedFeatures(vehicle);
    const bodyType = get(vehicleInfo, 'styleInfo.bodyType');
    const dealerName = get(dealerInfo, 'name', '');
    const dealerRating = get(dealerInfo, 'displayInfo.salesOverallRating', 0);
    const dealerReviewCount = get(dealerInfo, 'displayInfo.salesReviewCount', 0);
    const rooftopId = get(vehicle, 'dealerInfo.rooftopId', '');
    const keyPointsSimilarCards = isLlmCard
      ? compact([
          !isNewType && {
            icon: 'icon-meter',
            iconTitle: 'Car Mileage',
            content: mileage ? `${numberWithCommas(mileage)} ${getPlural('mile', mileage)}` : 'Not provided',
          },
          vin && {
            icon: 'icon-price-tag3',
            iconTitle: 'Vin',
            content: vin,
          },
        ])
      : compact([
          !isNewType && {
            icon: 'icon-meter',
            iconTitle: 'Car Mileage',
            content: mileage ? `${numberWithCommas(mileage)} ${getPlural('mile', mileage)}` : 'Not provided',
          },
          (!isNational || showNationalDelivery) && {
            icon: 'icon-location',
            iconTitle: 'Dealer Location',
            content: dealerLocationLabel,
          },
          !isNewType &&
            accidentText && {
              icon: getIconClasses({ accidentText, cleanTitle, isGrayIcons }),
              iconTitle: 'Vehicle History',
              content: getTitleDisplayDescription(historyInfo),
            },
          isFiveStarDealer && {
            icon: 'icon-5star',
            iconTitle: 'Five Star Dealer',
            content: 'Five Star Dealer ',
          },
          deliveryText && {
            icon: 'icon-transit',
            iconTitle: 'Shipping',
            content: <Fragment>{deliveryText}*</Fragment>,
          },
        ]);
    const accidentDisplay = getTitleDisplayDescription(historyInfo);
    const ownerDisplay = getOwnerDisplayDescription(historyInfo);
    let usageDisplay = getUsageTypeDisplayDescription(historyInfo, !isSimilarCards).toLowerCase();
    usageDisplay = usageDisplay.charAt(0).toUpperCase() + usageDisplay.slice(1);

    const isElectric = get(vehicle, 'vehicleInfo.partsInfo.engineType') === 'electric';
    const isRecurrent = isRecurrentAvailable(vehicle);
    const showRecurrentData = isSrp && isElectric && !isNewType && isRecurrent;

    const showElectricityRange = !isSimilarCards && isElectric && !showRecurrentData;
    const engineAttrIcon = getEngineAttrIcon(vehicleInfo, showElectricityRange);
    const engineAttr = getEngineAttr(vehicleInfo, showElectricityRange);

    let assignedDealerName = '';

    if (dealerLocationLabel === IN_STOCK_ONLINE) {
      assignedDealerName = ASSIGNED_DEALER_NAMES.includes(parentDealershipName) && parentDealershipName;
      assignedDealerName = parentDealershipName === CARMAX ? 'CarMax' : assignedDealerName;
    }

    const shouldRenderDealerRating = isNewType && !!dealerRating;

    const keyPoints = compact([
      !isNewType && {
        icon: 'icon-meter',
        iconTitle: 'Car Mileage',
        content: mileage ? `${numberWithCommas(mileage)} ${getPlural('mile', mileage)}` : 'Not provided',
      },
      !isNewType &&
        (accidentDisplay || ownerDisplay || usageDisplay) && {
          icon: getIconClasses({ accidentText, cleanTitle, isGrayIcons }),
          iconTitle: 'Vehicle History',
          content: compact([accidentDisplay, ownerDisplay, usageDisplay]).join(', '),
        },
      BODY_TYPES_SRP_EXPANDED.includes(bodyType) &&
        !showRecurrentData &&
        engineAttr && {
          icon: engineAttrIcon,
          iconTitle: 'Engine attribute',
          content: engineAttr,
        },
      (!isNational || showNationalDelivery) && {
        icon: 'icon-location',
        iconTitle: 'Dealer Location',
        content: (
          <span>
            <span>{assignedDealerName || dealerName} </span>
            <span
              className={classnames(
                {
                  'font-weight-bold': isBoldDealerLocationLabel,
                  'mr-0_25': shouldRenderDealerRating,
                },
                'text-cool-gray-40'
              )}
            >
              ({dealerLocationLabel})
            </span>
            {shouldRenderDealerRating && (
              <span className="text-nowrap">
                <RatingStars className="text-primary-darker medium" rating={dealerRating} />
                {!!dealerReviewCount && <span className="ml-0_25 text-cool-gray-40">({dealerReviewCount})</span>}
              </span>
            )}
          </span>
        ),
      },
      deliveryText && {
        icon: 'icon-transit',
        iconTitle: 'Shipping',
        content: <Fragment>{deliveryText}*</Fragment>,
        showFiveStar: isFiveStarDealer,
      },
      !deliveryText &&
        isFiveStarDealer && {
          icon: 'icon-5star text-blue-50',
          iconTitle: 'Five Star Dealer',
          content: <span className="size-12 font-weight-medium text-blue-50">Five Star Dealer</span>,
        },
    ]);

    const { paymentValue } = getPaymentDetails({
      prices,
      usePaymentType: isNewType,
      selectedPaymentType,
      paymentType,
    });
    const displayBelowMarketCopy = getDisplayBelowMarketCopy({
      paymentValue,
      isNew: isNewType,
      dealerStateCode,
      savings,
    });
    const vdpLink = getVdpLink({ vehicle, isNational, paymentType, radius });

    const isLeaseCardWithLeasePayment = this.isLeaseCardWithLeasePayment();
    const isLoanCardWithLoanPayment = this.isLoanCardWithLoanPayment();
    const SRP_CARD_PHOTO_LIMIT = 15;

    let vehicleNameDisplay = vehicleName;

    if (!isSimilarCards) {
      const [firstLine, secondLine] = getVehicleName(vehicle, isEnhancedName, true);
      vehicleNameDisplay = <VehicleNameDisplay vehicle={vehicle} firstLine={firstLine} secondLine={secondLine} />;
    }

    const ariaLabel = this.getVehicleNameAriaLabel();
    const ctaElement = isDrivewayDealer && isPartner && isUsedType ? null : this.getCtaElement(ariaLabel, photoUrls);

    return (
      <ClickableCard
        className={classnames('d-flex flex-column usurp-inventory-card overflow-hidden h-100', className, {
          'bg-white rounded': isSimilarCards,
          'w-100 rounded-12 bg-white': !isSimilarCards,
          'semantic-search-card': isLlmCard,
        })}
        data-dealer-rooftop-id={rooftopId}
      >
        <Fragment>
          <UsurpInventoryCardPhoto
            photos={photoUrls.slice(0, SRP_CARD_PHOTO_LIMIT)}
            hasPhotos={hasPhotos}
            provider={getPhotoProvider(vehicle)}
            vehicle={vehicle}
            visitorStateCode={visitorLocation.stateCode}
            isMobile={isMobile}
            vin={vin}
            photoType={photoType}
            vdpLink={vdpLink}
            clientSideRenderImage={clientSideRenderImage}
            lazyLoadImage={lazyLoadImage}
            importance={importance}
            isSimilarCards={isSimilarCards}
            isSrpExpanded={!isSimilarCards}
            isViewed={isViewed}
            showInventoryHeart={showInventoryHeart}
            showInventoryBadge={showInventoryBadge}
            tabIndex={tabIndex}
            showInTransit={isSrp}
            heartAriaLabel={ariaLabel}
            isLlmCard={isLlmCard}
            searchId={searchId}
            isVdpPartnerListing={isVdpPartnerListing}
          />
          <div
            className={classnames('vehicle-info d-flex flex-column p-1', {
              'justify-content-between': isSimilarCards,
            })}
          >
            <div className={classnames('visible-vehicle-info d-flex flex-column')}>
              {isSimilarCards && (
                <div className="d-flex align-items-center justify-content-between">
                  {isLeaseCardWithLeasePayment ? (
                    <PricingDetails
                      vehicle={vehicle}
                      displayBelowMarketCopy={displayBelowMarketCopy}
                      displayLeaseMonthlyPayment
                      displayDownPayment
                      displayDealBadge={false}
                      priceClasses={classnames({ 'heading-4': !isLlmCard })}
                    />
                  ) : (
                    <PricingDetails
                      vehicle={vehicle}
                      displayBelowMarketCopy={(isNewType || !isLoanCardWithLoanPayment) && displayBelowMarketCopy}
                      displayDealBadge={false}
                      displayLoanMonthlyPayment={isLoanCardWithLoanPayment}
                      displayMSRPCopyRight={(isNewType && !selectedPaymentType) || isLlmCard}
                      noPriceClasses=""
                      priceClasses={classnames({ 'heading-4': !isLlmCard })}
                    />
                  )}
                </div>
              )}
              {isSimilarCards && (
                <h2 className="card-title size-16 text-primary-darker font-weight-bold d-block mb-0_5 mt-0_25">
                  <ClickableCardLink
                    linkComponent={UsurpInventoryCardVdpLink}
                    className="wrapping-vdp-link"
                    vehicle={vehicle}
                    vdpLink={vdpLink}
                    isSimilarCards={isSimilarCards}
                    isLlmCard={isLlmCard}
                    searchId={searchId}
                    isVdpPartnerListing={isVdpPartnerListing}
                    tabIndex={tabIndex}
                    ariaLabel={ariaLabel}
                  >
                    {vehicleName}
                  </ClickableCardLink>
                </h2>
              )}
              {!isSimilarCards && (
                <UsurpInventoryCardInfo
                  vehicle={vehicle}
                  selectedPaymentType={selectedPaymentType}
                  ariaLabel={ariaLabel}
                  vehicleNameDisplay={vehicleNameDisplay}
                  vdpLink={vdpLink}
                  displayBelowMarketCopy={displayBelowMarketCopy}
                  isLeaseCardWithLeasePayment={isLeaseCardWithLeasePayment}
                  isLoanCardWithLoanPayment={isLoanCardWithLoanPayment}
                  isShop2901Enabled={isShop2901Enabled}
                />
              )}
              <Row className={classnames({ 'text-gray-darker': !isSimilarCards }, 'list-unstyled mb-0')} tag="ul">
                {isNewType && !isLlmCard ? (
                  <Fragment>
                    <Col style={{ lineHeight: '1.125rem' }} className="col-auto pr-0" tag="li">
                      {exteriorColorName !== DEFAULT_COLOR && (
                        <ColorInfo
                          color={exteriorColor}
                          colorName={exteriorColorName}
                          colorSide="Exterior"
                          {...(!isSimilarCards
                            ? {
                                label: exteriorColorName,
                                style: {
                                  marginRight: '1rem',
                                  marginTop: '0.5rem',
                                  display: 'inline-block',
                                  lineHeight: '1.125rem',
                                },
                                isSrpExpanded: true,
                              }
                            : {
                                label: 'Exterior',
                              })}
                        />
                      )}
                    </Col>
                    <Col style={{ lineHeight: '1.125rem' }} className="col-auto" tag="li">
                      {interiorColorName !== DEFAULT_COLOR && (
                        <ColorInfo
                          color={interiorColor}
                          colorName={interiorColorName}
                          colorSide="Interior"
                          {...(!isSimilarCards
                            ? {
                                label: interiorColorName,
                                style: { marginTop: '0.5rem', display: 'inline-block', lineHeight: '1.125rem' },
                                isSrpExpanded: true,
                              }
                            : {
                                label: 'Interior',
                              })}
                        />
                      )}
                    </Col>
                    {!isSimilarCards ? (
                      <UsurpCardKeyPoint
                        keyPoints={keyPoints}
                        isGrayIcons={isGrayIcons}
                        tag={Col}
                        columnSizes={{ xs: 12 }}
                        htmlTag="li"
                      />
                    ) : (
                      keyPointsSimilarCards.map(point => (
                        <Col xs={12} tag="li" key={point.iconTitle}>
                          <div className="size-14 mt-0_25">
                            <KeyPointSimilarCard
                              {...point}
                              classes={classnames({
                                'text-gray-darker': isGrayIcons,
                                'text-primary-darker': !isGrayIcons,
                              })}
                            />
                          </div>
                        </Col>
                      ))
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {!isSimilarCards ? (
                      <UsurpCardKeyPoint
                        tag={Col}
                        columnSizes={{ xs: 12 }}
                        keyPoints={keyPoints}
                        isGrayIcons={isGrayIcons}
                        htmlTag="li"
                      />
                    ) : (
                      keyPointsSimilarCards.map(point => (
                        <Col xs={12} key={point.iconTitle} className="size-14 mt-0_25" tag="li">
                          <KeyPointSimilarCard
                            {...point}
                            classes={classnames({
                              'text-gray-darker': isGrayIcons,
                              'text-primary-darker': !isGrayIcons,
                            })}
                          />
                        </Col>
                      ))
                    )}
                  </Fragment>
                )}
              </Row>
              {!isSimilarCards && <TopFeatureBubbles topFeatures={topFeatures} className="mt-1" />}
              {showRecurrentData && <RecurrentInsights vehicle={vehicle} className="mt-1" />}
              {!isSimilarCards && ctaElement}
            </div>
            {isSimilarCards && <div>{ctaElement}</div>}
            {!isSimilarCards && (
              <UsurpCardViewMore
                vehicle={vehicle}
                vehicleName={vehicleName}
                visitorLocation={visitorLocation}
                isLeaseCardWithLeasePayment={isLeaseCardWithLeasePayment}
              />
            )}
          </div>
        </Fragment>
      </ClickableCard>
    );
  }
}
